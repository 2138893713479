<template>
  <v-container>
    <v-row class="mt-2">
      <v-col align="center">
        <v-card :loading="loading" max-width="900">
          <error-overlay v-model="error" @retry="loadData">
            <v-btn
              v-if="deleteApp"
              color="#006c27"
              class="white--text"
              @click="deleteThis($event)"
            >
              Delete
            </v-btn>
          </error-overlay>
          <template slot="progress">
            <v-progress-linear
              color="#006c27"
              height="10"
              indeterminate
            ></v-progress-linear>
          </template>
          <v-card-title class="text--secondary">
            Application Details
            <v-spacer />
            <v-chip
              id="applicationEnvironment"
              outlined
              v-if="application != null"
              :color="getEnvironmentColor(application.environment)"
              dark
            >
              {{ application.environment }}
            </v-chip>
          </v-card-title>
          <v-card-text v-if="application != null" class="text--primary my-3">
            <v-row>
              <v-col>
                <v-text-field
                  id="applicationDisplayName"
                  :value="application.displayName"
                  label="Name"
                  outlined
                  readonly
                  hide-details
                  color="#006c27"
                  append-icon="mdi-pencil-outline"
                  @click:append="editAppName"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-text-field
                  id="applicationId"
                  :value="application.applicationId"
                  label="CASS Application ID"
                  outlined
                  readonly
                  hide-details
                  color="#006c27"
                >
                  <v-icon
                    v-if="this.$userData.cass.isUserAdmin"
                    style="margin-top: 2px"
                    dense
                    slot="append"
                    @click="linkAzureAppReg"
                    class="mr-2"
                  >
                    mdi-link
                  </v-icon>
                  <v-icon
                    style="margin-top: 2px"
                    dense
                    slot="append"
                    @click="copy2clipboard(application.applicationId)"
                  >
                    mdi-content-copy
                  </v-icon>
                </v-text-field>
              </v-col>
              <v-col v-if="this.$userData.cass.isUserAdmin">
                <v-text-field
                  id="applicationApiMgmtApplicationId"
                  :value="
                    application.apiMgmtApplicationId | formatStringToLowerCase
                  "
                  label="SAP Application ID"
                  outlined
                  readonly
                  hide-details
                  color="#006c27"
                >
                  <v-icon
                    v-if="this.$userData.cass.isUserAdmin"
                    style="margin-top: 2px"
                    dense
                    slot="append"
                    @click="linkDeveloperPortal"
                    class="mr-2"
                  >
                    mdi-link
                  </v-icon>
                  <v-icon
                    style="margin-top: 2px"
                    dense
                    slot="append"
                    @click="copy2clipboard(application.apiMgmtApplicationId)"
                  >
                    mdi-content-copy
                  </v-icon>
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-0">
              <v-col>
                <v-text-field
                  id="applicationCreateDate"
                  :value="application.createDate | formatDate"
                  label="Created At"
                  outlined
                  readonly
                  hide-details
                  color="#006c27"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  id="applicationPermissionModel"
                  :value="application.permissionModel | formatString"
                  label="Permission Model"
                  outlined
                  readonly
                  hide-details
                  color="#006c27"
                >
                </v-text-field>
              </v-col>
              <v-col>
                <v-text-field
                  id="applicationState"
                  :value="application.state | formatString"
                  label="Status"
                  outlined
                  readonly
                  hide-details
                  color="#006c27"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-expansion-panels :multiple="true" class="mx-3 my-3">
                <i-t-sec-demand
                  v-if="isUserSchaeffler()"
                  :application="application"
                  :dataLoading="loading"
                ></i-t-sec-demand>
                <owners :dataLoading="loading" :application="application"></owners>
                <usage-statistics :application="application"></usage-statistics>
                <secrets :dataLoading="loading" :application="application"></secrets>
                <certificates :dataLoading="loading" :application="application"></certificates>
                <redirect :dataLoading="loading" :application="application"></redirect>
                <sub-products
                  :application="application"
                  :dataLoading="loading"
                  :reload="loadData"
                ></sub-products>
                <permissions :dataLoading="loading" :application="application"></permissions>
                <o-auth-2 :application="application"></o-auth-2>
              </v-expansion-panels>
            </v-row>
            <v-row
              style="margin-top: 18px; margin-bottom: 0px; margin-right: 1px"
            >
              <v-spacer />

              <v-btn @click="back" class="white--text mr-3" color="#006c27">
                <v-icon color="white" class="mr-1">mdi-arrow-left</v-icon>
                back</v-btn
              >

              <v-btn
                @click="showGuide"
                class="white--text mr-3"
                color="#006c27"
              >
                <v-icon color="white" class="mr-1">mdi-help</v-icon>
                HELP</v-btn
              >

              <v-btn
                @click="onDeleteApplication"
                id="deleteApplicationButton"
                class="white--text"
                color="#006c27"
              >
                <v-icon color="white" class="mr-1"
                  >mdi-trash-can-outline</v-icon
                >
                Delete</v-btn
              >
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <application-guide-overlay
      v-model="guideOverlay"
      :application="application"
    ></application-guide-overlay>
    <application-delete-overlay
      v-if="application != undefined"
      v-model="deleteOverlay"
      :safeDelete="safeDelete"
      :application="application"
    ></application-delete-overlay>
    <application-rename-overlay
      v-if="application != undefined"
      v-model="appNameEdit"
      :application="application"
      @newname="updateName"
    ></application-rename-overlay>
  </v-container>
</template>

<script>
import cass from "../api/cass";
import ErrorOverlay from "../components/ErrorOverlay.vue";
import ITSecDemand from "../components/ITSecDemand.vue";
import Owners from "../components/Owners.vue";
import moment from "moment";
import Secrets from "../components/Secrets.vue";
import Certificates from "../components/Certificates.vue";
import Redirect from "../components/Redirect.vue";
import SubProducts from "../components/SubProducts.vue";
import UsageStatistics from "../components/UsageStats.vue";
import Permissions from "../components/Permissions.vue";
import OAuth2 from "../components/OAuth2.vue";
import ApplicationDeleteOverlay from "../components/ApplicationDeleteOverlay.vue";
import ApplicationGuideOverlay from "../components/ApplicationGuideOverlay.vue";
import ApplicationRenameOverlay from "../components/ApplicationRenameOverlay.vue";

export default {
  name: "Application",

  components: {
    ErrorOverlay,
    Owners,
    Secrets,
    UsageStatistics,
    Certificates,
    Redirect,
    SubProducts,
    Permissions,
    OAuth2,
    ApplicationDeleteOverlay,
    ApplicationGuideOverlay,
    ApplicationRenameOverlay,
    ITSecDemand,
  },
  created() {
    console.log("APPLICATION CREATE");
    this.$emitter.on("cert_update", () => {
      console.log("cert_update");
      this.loadData();
    });
    this.$emitter.on("application_update", () => {
      console.log("application_update");
      this.applicationUpdate();
    });

    this.$emitter.on("retry", () => {
      console.log("retry");
      this.loadData();
    });
    if (this.$userData.cass.isUserAdmin) {
      this.safeDelete = true;
    }
  },
  async mounted() {
    this.loadData();
  },
  destroyed() {
    console.log("APPLICATION UNMOUNTED");
    this.$emitter.off("cert_update");
  },
  data() {
    return {
      safeDelete: false,
      appNameEdit: false,
      application: null,
      error: null,
      loading: true,
      user: null,
      deleteOverlay: false,
      deleteApp: false,
      guideOverlay: false,
      //
      environmentName: Object.freeze({
        PRODUCTION: "PRODUCTION", //
        QUALITY: "QUALITY", //
        DEVELOPMENT: "DEVELOPMENT",
      }),
      environmentUrl: Object.freeze({
        PRODUCTION:
          "https://prodeu2devportalapimgmtpeu2-xbco389jhy.eu2.hana.ondemand.com/",
        QUALITY:
          "https://prodeu2devportalapimgmtpeu2-u388hr22dq.eu2.hana.ondemand.com/",
        DEVELOPMENT:
          "https://prodeu2devportalapimgmtpeu2-ed119f165.eu2.hana.ondemand.com/",
      }),
      environmentColor: Object.freeze({
        PRODUCTION: "#006c27", //
        QUALITY: "#fccf46", //
        DEVELOPMENT: "#1d9bb2",
      }),
    };
  },
  filters: {
    formatDate: function (value) {
      return moment(String(value)).format("MMMM Do YYYY, HH:mm:ss");
    },
    formatStringToLowerCase(value) {
      return value.toLowerCase();
    },
    formatString(value) {
      return (
        value.charAt(0).toUpperCase() +
        value.replaceAll("_", " ").slice(1).toLowerCase()
      );
    },
  },
  methods: {
    updateName(name) {
      console.log("new name:", name);
      this.application.displayName = name;
    },
    editAppName() {
      this.appNameEdit = true;
    },
    isUserSchaeffler() {
      if (this.$userData.cass.email.includes("@schaeffler.com")) {
        return true;
      }
      return false;
    },
    back() {
      if (this.$route.name != "Home") {
        this.$router
          .push({
            name: "Home",
          })
          .catch(() => {
            console.log("Something bad happend .. start over");
            window.location.reload();
          });
      }
    },
    applicationUpdate() {
      this.error = null;
      this.loading = true;
      cass
        .getApplication(this.$route.params.applicationid)
        .then(async (response) => {
          console.log(response);
          response.data.permissions = [];
          response.data.subscriptions = [];

          if (
            this.application != null &&
            this.application.permissions != undefined
          ) {
            response.data.permissions = this.application.permissions;
          }

          if (
            this.application != null &&
            this.application.subscriptions != undefined
          ) {
            response.data.subscriptions = this.application.subscriptions;
          }

          this.application = response.data;
        })
        .catch((error) => {
          let casserror = cass.handleError(error);
          console.log(casserror);
          if (
            casserror.message === "Application was deleted on Developer Portal"
          ) {
            this.deleteApp = true;
          }
          this.error = casserror;
        })
        .finally(() => {
          this.$emitter.emit("data_update");
          this.loading = false;
        });
    },
    loadData() {
      this.error = null;
      this.loading = true;
      cass
        .getApplication(this.$route.params.applicationid)
        .then(async (response) => {
          console.log(response);
          response.data.permissions = [];
          response.data.subscriptions = [];

          if (
            this.application != null &&
            this.application.permissions != undefined
          ) {
            response.data.permissions = this.application.permissions;
          }

          if (
            this.application != null &&
            this.application.subscriptions != undefined
          ) {
            response.data.subscriptions = this.application.subscriptions;
          }

          this.application = response.data;
          return cass.getPermissions(this.$route.params.applicationid);
        })
        .then((permission) => {
          console.log(permission);
          this.application.permissions = permission.data;
          return cass.getSubscriptions(this.application.applicationId);
        })
        .then((subscriptions) => {
          console.log(subscriptions);
          this.application.subscriptions = subscriptions.data;
        })
        .catch((error) => {
          let casserror = cass.handleError(error);
          console.log(casserror);
          if (
            casserror.message === "Application was deleted on Developer Portal"
          ) {
            this.deleteApp = true;
          }
          this.error = casserror;
        })
        .finally(() => {
          this.$emitter.emit("data_update");
          this.loading = false;
        });
    },
    getEnvironmentColor(env) {
      if (env == this.environmentName.PRODUCTION) {
        return this.environmentColor.PRODUCTION;
      } else if (env == this.environmentName.QUALITY) {
        return this.environmentColor.QUALITY;
      } else if (env == this.environmentName.DEVELOPMENT) {
        return this.environmentColor.DEVELOPMENT;
      } else {
        console.log("Couldn't detect environment: " + env);
      }
    },
    getDevPortalLink(env) {
      if (env == this.environmentName.PRODUCTION) {
        return this.environmentUrl.PRODUCTION;
      } else if (env == this.environmentName.QUALITY) {
        return this.environmentUrl.QUALITY;
      } else if (env == this.environmentName.DEVELOPMENT) {
        return this.environmentUrl.DEVELOPMENT;
      } else {
        console.log("Couldn't detect environment: " + env);
      }
    },
    linkDeveloperPortal() {
      let url = this.getDevPortalLink(this.application.environment);
      url += "application/" + this.application.apiMgmtApplicationId;

      window.open(url, "_blank").focus();
    },
    linkAzureAppReg() {
      let url =
        "https://portal.azure.com/#blade/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/Overview/appId/" +
        this.$route.params.applicationid;

      window.open(url, "_blank").focus();
    },
    copy2clipboard(value) {
      console.log("copy");
      navigator.clipboard.writeText(value);
    },
    onDeleteApplication() {
      this.deleteOverlay = true;
    },
    showGuide() {
      this.guideOverlay = true;
    },
    deleteThis() {
      console.log("Deleting: " + this.$route.params.applicationid);
      this.error.loading = true;
      cass
        .deleteApplication(this.$route.params.applicationid, true)
        .then((response) => {
          console.log(response);
          this.$router
            .push({
              name: "Home",
            })
            .catch(() => {
              console.log("Something bad happend .. start over");
              window.location.reload();
            });
        })
        .catch((error) => {
          this.deleteApp = false;
          let casserror = cass.handleError(error);
          console.log(casserror);
          this.error = casserror;
        });
    },
  },
};
</script>

<style type="css" scoped>
.material-icons {
  vertical-align: middle;
}
</style>
