import { render, staticRenderFns } from "./Redirect.vue?vue&type=template&id=394f7a16&scoped=true"
import script from "./Redirect.vue?vue&type=script&lang=js"
export * from "./Redirect.vue?vue&type=script&lang=js"
import style0 from "./Redirect.vue?vue&type=style&index=0&id=394f7a16&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394f7a16",
  null
  
)

export default component.exports