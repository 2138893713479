<template>
  <v-expansion-panel :loading="true">
    <v-expansion-panel-header class="pl-3">
      <div>
        <v-icon> mdi-account-key-outline </v-icon>
        <span class="ml-1">Permissions</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <v-row
        v-if="application.permissions.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-col style="text-align: left"> No permissions available </v-col>
      </v-row>
      <v-row
        v-if="application.permissions.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-divider></v-divider>
      </v-row>
      <v-row
        v-if="application.permissions.length != 0"
        style="padding-left: 50px; padding-right: 80px; padding-bottom: 10px"
      >
        <v-col cols="9" style="text-align: left">
          <strong>Name</strong>
        </v-col>
        <v-col cols="3" style="text-align: left">
          <strong>Status</strong>
        </v-col>
      </v-row>
      <template v-for="(permission, index) in sortedPermissions">
        <v-list-item
          @click="doIt(permission)"
          dense
          :key="index"
          :selectable="false"
        >
          <v-list-item-icon style="margin-right: 10px">
            <v-icon>mdi-shield-key-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-row>
              <v-col cols="9" style="text-align: left">
                {{ permission.name }}
                <v-chip
                  x-small
                  outlined
                  @click="showDeprecation($event, permission.deprecation)"
                  color="red"
                  v-if="permission.deprecation"
                >
                  {{ getDeprecationChipText(permission.deprecation.State) }}
                </v-chip>
              </v-col>
              <v-col cols="3" style="text-align: left">
                {{ permission.status | formatString }}
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-icon>
            <v-tooltip bottom v-if="isUserSchaeffler()">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  :disabled="isAuditLogDisabled(permission)"
                  @click="auditLog($event, permission)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-script-text-key-outline</v-icon
                >
              </template>
              <span>Audit Log</span>
            </v-tooltip>
            <v-icon
              v-if="permission.status !== 'NOT_REQUESTED'"
              :disabled="loading"
              @click="onDeletePermission($event, permission.permissionId)"
              >mdi-trash-can-outline</v-icon
            >
            <v-icon
              v-if="permission.status === 'NOT_REQUESTED'"
              :disabled="
                loading ||
                (permission.deprecation &&
                  permission.deprecation.State !== 'ACTIVE')
              "
              @click="onTransferPermission($event, permission)"
              >mdi-plus-circle-outline</v-icon
            >
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="index + 100"></v-divider>
      </template>
      <v-list-item dense class="mt-5">
        <v-list-item-icon style="margin-right: 10px">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" @click="msgraphpermissions"
                >mdi-script-text-key-outline</v-icon
              >
            </template>
            <span
              >Use this field to add API permissions from Azure!<br />
              Click the Icon to get more information!</span
            >
          </v-tooltip>
        </v-list-item-icon>
        <v-form
          v-on:submit.prevent="nop"
          ref="form"
          style="width: 100%"
          v-model="isPermissionValid"
        >
          <v-text-field
            ref="textfield"
            v-model="addPermission"
            :rules="[rules.permissionRules]"
            :error-messages="severError"
            counter
            maxlength="120"
            label="Add MS Azure Permission"
            placeholder="Enter valid azure permission"
            dense
            color="#006c27"
          >
          </v-text-field>
        </v-form>
        <v-list-item-icon>
          <v-btn
            icon
            :loading="loading"
            :disabled="!isPermissionValid || dataLoading"
            @click="onAddPermission"
          >
            <v-icon>mdi-plus-circle-outline</v-icon>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-expansion-panel-content>
    <v-snackbar :timeout="3000" v-model="showSnackError" bottom color="#006c27">
      {{ snackErrorMessage }}
    </v-snackbar>
    <permission-detail-overlay
      v-model="detaildialog"
      @close="closePermissionDetail"
    >
    </permission-detail-overlay>
    <confirm-delete-overlay
      v-model="confirmDelete"
      @confirm="onRealDeletePermission"
    >
    </confirm-delete-overlay>
    <permission-submit
      v-model="permissionSubmit"
      :permissionName="permissionName"
      :permissionRequestText="permissionRequestText"
      :application="application"
      @submit="submit"
    >
    </permission-submit>
    <deprecation-detail-overlay
      v-model="showDeprecationDetail"
      :deprecation="selectedDeprecation"
      @close="showDeprecationDetail = false"
    >
    </deprecation-detail-overlay>
  </v-expansion-panel>
</template>

<script>
import cass from "../api/cass";
import ConfirmDeleteOverlay from "./ConfirmDeleteOverlay.vue";
import PermissionDetailOverlay from "./PermissionDetailOverlay.vue";
import PermissionSubmit from "./PermissionSubmit.vue";
import DeprecationDetailOverlay from "./DeprecationDetailOverlay.vue";

export default {
  name: "Permissions",
  components: {
    PermissionDetailOverlay,
    ConfirmDeleteOverlay,
    PermissionSubmit,
    DeprecationDetailOverlay,
  },
  data() {
    return {
      showDeprecationDetail: false,
      selectedDeprecation: null,
      permissionSubmit: false,
      permissionName: "",
      permissionRequestText: "",
      detaildialog: {
        show: false,
        permission: null,
      },
      confirmDelete: false,
      confirmedDeleteId: null,
      loading: false,
      addPermission: null,
      isPermissionValid: false,
      severError: null,
      showSnackError: false,
      snackErrorMessage: "",
      rules: {
        permissionRules: (value) => {
          if (this.severError != null) {
            this.severError = null;
          }
          if (value == null || value == undefined) {
            return false;
          }
          if (value == "") {
            return false;
          }
          if (!value.startsWith("https://")) {
            return "must start with 'https://'";
          }
          if (value.length > 0) {
            return true;
          }
        },
      },
    };
  },
  created() {
    console.log("PERMISSION CREATE");
    this.$emitter.on("data_update", () => {
      //console.log("data_update");
      this.loading = false;
    });
  },
  props: {
    application: Object,
    dataLoading: Boolean,
  },
  computed: {
    sortedPermissions: function () {
      let tempPermissions = this.application.permissions;

      function compare(a, b) {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      }

      return tempPermissions.sort(compare);
    },
  },
  methods: {
    msgraphpermissions() {
      window
        .open(
          "https://learn.microsoft.com/en-us/graph/permissions-reference",
          "_blank"
        )
        .focus();
    },
    getDeprecationChipText(state) {
      if (state == "ACTIVE") {
        return "Scheduled";
      }
      return state.charAt(0) + state.substring(1).toLowerCase();
    },
    showDeprecation(evt, deprecation) {
      evt.stopPropagation();
      console.log(deprecation);
      this.selectedDeprecation = deprecation;
      this.showDeprecationDetail = true;
    },
    submit(reasonText) {
      this.onAddPermissionReal(window.btoa(reasonText));
    },
    closePermissionDetail() {
      this.detaildialog.show = false;
    },
    doIt(permission) {
      this.detaildialog.permission = permission;
      this.detaildialog.show = true;
    },
    onAddPermission() {
      this.permissionName = this.addPermission;
      this.permissionRequestText =
        "Please fill out the request form. This will be send to the Azure global admins.";
      this.permissionSubmit = true;
    },
    onAddPermissionReal(reason) {
      console.log(this.permissionName);
      this.loading = true;
      cass
        .addPermission(
          this.application.applicationId,
          this.permissionName,
          reason
        )
        .then((response) => {
          this.$refs.form.reset();
          console.log(response);
          this.$emitter.emit("cert_update");
          this.snackErrorMessage = "A confirmation e-mail was sent to you";
          this.showSnackError = true;
        })
        .catch((err) => {
          // This must be better
          // Timeout handling?
          console.log(err);
          if (err.response) {
            console.log(err.response);
            if (!err.response.data.message) {
              this.severError = "Network communication error occured";
            } else {
              this.severError = err.response.data.message;
            }
            this.loading = false;
          } else {
            this.severError = "Network communication error occured";
            this.loading = false;
          }
        });
    },
    onTransferPermission(evt, permission) {
      evt.stopPropagation();
      let text = permission.permissionRequestInfo;

      if (typeof text !== "string" || text == undefined || text == "") {
        this.permissionRequestText = "Please fill out the request form";
      } else {
        this.permissionRequestText = text;
      }
      this.permissionName = permission.name;
      this.permissionSubmit = true;
    },
    onDeletePermission(evt, permissionid) {
      evt.stopPropagation();
      this.confirmedDeleteId = permissionid;
      this.confirmDelete = true;
    },
    onRealDeletePermission() {
      this.loading = true;
      cass
        .deletePermission(
          this.application.applicationId,
          this.confirmedDeleteId
        )
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          // This must be better
          // Timeout handling?
          console.log(err);
          console.log(err.response);
          this.snackErrorMessage = err.response.data.message;
          this.showSnackError = true;
          this.loading = false;
        });
    },
    retry() {
      this.$emit("retry");
    },
    isUserSchaeffler() {
      if (this.$userData.cass.email.includes("@schaeffler.com")) {
        return true;
      }
      return false;
    },
    isAuditLogDisabled(permission) {
      if (!this.isUserSchaeffler()) {
        return true;
      }
      if (permission.status === "NOT_REQUESTED") {
        return true;
      }
      return false;
    },
    nop() {},
    auditLog(evt, item) {
      evt.stopPropagation();
      this.$router
        .push({
          name: "Permissions",
          params: {
            applicationid: this.application.applicationId,
            permissionid: item.permissionId,
          },
        })
        .catch(() => {
          console.log("Something bad happend .. start over");
          window.location.reload();
        });
    },
  },
  filters: {
    formatString(value) {
      return (
        value.charAt(0).toUpperCase() +
        value.replaceAll("_", " ").slice(1).toLowerCase()
      );
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
