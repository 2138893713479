<template>
  <v-expansion-panel>
    <v-expansion-panel-header id="redirectUriList" class="pl-3">
      <div>
        <v-tooltip bottom v-if="application.state == 'MISSING_REDIRECTURI'">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on" color="red">
              mdi-responsive</v-icon
            >
          </template>
          <span>Your application needs at least one valid redirectURI</span>
        </v-tooltip>
        <v-icon v-else> mdi-responsive </v-icon>
        <span class="ml-1">Redirect URIs</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <v-row
        v-if="application.redirectURIs.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-col style="text-align: left"> No URIs available </v-col>
      </v-row>
      <v-row
        v-if="application.redirectURIs.length == 0"
        style="padding-left: 50px; padding-right: 56px; padding-bottom: 10px"
      >
        <v-divider></v-divider>
      </v-row>
      <template v-for="(redirect, index) in application.redirectURIs">
        <v-list-item dense :key="index" :selectable="false">
          <v-list-item-icon style="margin-right: 10px">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on">{{
                  getPlatformIcon(redirect.type)
                }}</v-icon>
              </template>
              <span>{{ redirect.type }} </span>
            </v-tooltip>
          </v-list-item-icon>
          <v-list-item-content style="text-align: left">
            <v-row class="flex-nowrap">
              <v-col>
                {{ redirect.redirectURI }}
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              :id="'deleteRedirectButton_' + index"
              @click="deleteRedirectURI($event, redirect.redirectURIId)"
              >mdi-trash-can-outline</v-icon
            >
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="redirect.redirectURIId"></v-divider>
      </template>
      <v-list-item dense class="mt-5">
        <v-spacer></v-spacer>
        <div>Add RedirectURI</div>
        <v-list-item-icon>
          <v-tooltip :disabled="!addRedirectDisabled()" bottom>
            <template v-slot:activator="{ on }">
              <span v-on="on">
                <v-icon
                  id="addRedirectUriButton"
                  :disabled="addRedirectDisabled() || dataLoading"
                  @click="addRedirectURI"
                  >mdi-plus-circle-outline</v-icon
                >
              </span>
            </template>
            <span v-html="getDisabledToolTip()"></span>
          </v-tooltip>
        </v-list-item-icon>
      </v-list-item>
    </v-expansion-panel-content>
    <v-snackbar :timeout="3000" v-model="showSnackError" bottom color="#006c27">
      {{ snackErrorMessage }}
    </v-snackbar>
    <redirect-overlay
      :application="application"
      v-model="redirectDialog"
    ></redirect-overlay>
    <confirm-delete-overlay
      v-model="confirmDelete"
      @confirm="onRealDeleteRedirectURI"
    >
    </confirm-delete-overlay>
  </v-expansion-panel>
</template>

<script>
import cass from "../api/cass";
import RedirectOverlay from "./RedirectOverlay";
import ConfirmDeleteOverlay from "./ConfirmDeleteOverlay.vue";

export default {
  name: "Redirect",
  components: {
    RedirectOverlay,
    ConfirmDeleteOverlay,
  },
  data() {
    return {
      confirmDelete: false,
      confirmedDeleteId: null,
      showSnackError: false,
      snackErrorMessage: "",
      redirectDialog: false,
    };
  },
  props: {
    application: Object,
    dataLoading: Boolean,
  },
  methods: {
    onRealDeleteRedirectURI() {
      cass
        .deleteRedirectURI(
          this.application.applicationId,
          this.confirmedDeleteId
        )
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.snackErrorMessage = err.response.data.message;
          this.showSnackError = true;
        });
    },
    addRedirectDisabled() {
      if (this.application.permissionModel === "APPLICATION") {
        return true;
      }
      return false;
    },
    getDisabledToolTip() {
      return "Redirect URIs can only be registered for<br/>applications in permission model delegated";
    },
    retry() {
      this.$emit("retry");
    },
    getPlatformIcon(type) {
      if (type === "iOS") {
        return "mdi-apple";
      }
      if (type === "ANDROID") {
        return "mdi-android";
      }
      if (type === "SPA") {
        return "mdi-newspaper-variant";
      }
      if (type === "WEB") {
        return "mdi-web";
      }
      if (type === "DESKTOP") {
        return "mdi-desktop-mac";
      }
    },
    addRedirectURI() {
      this.redirectDialog = true;
    },
    deleteRedirectURI(evt, uriid) {
      evt.stopPropagation();
      this.confirmedDeleteId = uriid;
      this.confirmDelete = true;
    },
  },
};
</script>

<style scoped>
.overflow {
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 100%;
}
</style>
