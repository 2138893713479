<template>
  <v-expansion-panel>
    <v-expansion-panel-header id="ownersList" class="pl-3">
      <div>
        <v-icon> mdi-account-group-outline </v-icon>
        <span class="ml-1">Owners</span>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content style="padding-left: 0px">
      <template v-for="(owners, index) in application.owners">
        <v-list-item dense :key="index" :selectable="false">
          <v-list-item-icon style="margin-right: 10px">
            <v-icon>mdi-account-outline</v-icon>
          </v-list-item-icon>
          <v-list-item-content style="text-align: left">
            <v-list-item-title
              :id="'ownerId_' + index"
              v-text="owners"
            ></v-list-item-title>
          </v-list-item-content>
          <v-list-item-icon>
            <v-icon
              :id="'deleteOwnerButton_' + index"
              @click="deleteOwner($event, owners)"
              >mdi-trash-can-outline</v-icon
            >
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="owners"></v-divider>
      </template>
      <v-list-item dense class="mt-5">
        <v-list-item-icon style="margin-right: 10px">
          <v-icon>mdi-account-outline</v-icon>
        </v-list-item-icon>
        <v-form
          v-on:submit.prevent="nop"
          ref="form"
          style="width: 100%"
          v-model="isEmailValid"
        >
          <v-text-field
            ref="textfield"
            id="addOwnerInput"
            v-model="addEmail"
            :rules="[rules.emailRules]"
            :error-messages="severError"
            counter
            maxlength="70"
            label="Add Owner"
            placeholder="Enter valid email address (please use the short email address for Schaeffler users)"
            dense
            color="#006c27"
          >
          </v-text-field>
        </v-form>
        <v-list-item-icon>
          <v-btn
            icon
            id="addOwnerButton"
            :loading="loading"
            :disabled="!isEmailValid || dataLoading"
            @click="addOwner"
          >
            <v-icon>mdi-account-plus-outline</v-icon>
            <template v-slot:loader>
              <span class="custom-loader">
                <v-icon light>mdi-cached</v-icon>
              </span>
            </template>
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-expansion-panel-content>
    <v-snackbar :timeout="3000" v-model="showSnackError" bottom color="#006c27">
      {{ snackErrorMessage }}
    </v-snackbar>
    <confirm-delete-overlay
      v-model="confirmDelete"
      @confirm="onRealDeleteOwner"
    >
    </confirm-delete-overlay>
  </v-expansion-panel>
</template>

<script>
import cass from "../api/cass";
import ConfirmDeleteOverlay from "./ConfirmDeleteOverlay.vue";

export default {
  name: "Owners",
  components: { ConfirmDeleteOverlay },
  props: {
    application: Object,
    dataLoading: Boolean,
  },
  data() {
    return {
      confirmDelete: false,
      confirmedDeleteId: null,
      loading: false,
      addEmail: null,
      isEmailValid: false,
      severError: null,
      showSnackError: false,
      snackErrorMessage: "",
      rules: {
        emailRules: (value) => {
          if (this.severError != null) {
            this.severError = null;
          }
          if (value == null || value == undefined) {
            return false;
          }
          if (value.length > 0) {
            const pattern =
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(value) || "Please enter a valid e-mail.";
          }
        },
      },
    };
  },

  methods: {
    onRealDeleteOwner() {
      console.log(this.confirmedDeleteId);
      cass
        .deleteOwner(this.application.applicationId, this.confirmedDeleteId)
        .then((response) => {
          console.log(response);
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.snackErrorMessage = err.response.data.message;
          this.showSnackError = true;
        });
    },
    retry() {
      this.$emit("retry");
    },
    nop() {},
    addOwner() {
      this.loading = true;
      console.log(this.addEmail);
      cass
        .addOwner(this.application.applicationId, this.addEmail)
        .then((response) => {
          this.$refs.form.reset();
          console.log(response);
          this.$emitter.emit("cert_update");
        })
        .catch((err) => {
          console.log(err);
          console.log(err.response);
          this.severError = err.response.data.message;
        })
        .finally(() => (this.loading = false));
    },
    deleteOwner(evt, owner) {
      evt.stopPropagation();
      this.confirmedDeleteId = owner;
      this.confirmDelete = true;
    },
  },
};
</script>

<style scoped>
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
